.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.animated {
  -webkit-animation-duration: 10s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation: pulse 3s infinite;
   
}
@-webkit-keyframes pulse {
  0% { -webkit-transform: scale(1); }
  50% { -webkit-transform: scale(1.1); }
  100% { -webkit-transform: scale(1); }
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
